<template>
  <v-sheet :class="darkmode ? darkColor : 'white'">
    <v-container>
      <v-row no-gutters>
        <!-- <v-col cols="12" sm="4" md="3" class="pb-12">
          <v-card class="white--text text-center" style="max-height:380px">
            <v-img
              gradient="to top, rgba(73,155,234,0.92), rgba(232,236,240,0.18)"
              src="@/assets/homepage/pengumuman/Image5.png"
              max-height="380px"
            >
              <div
                class="px-8 pb-6 d-flex justify-center align-end"
                style="min-height: 100%"
              >
                <div>
                  <p class="font-weight-bold">Pensi Binomo School</p>
                  <p class="caption">
                    Pensi menyambut siswa & siswi binomo school tahun ajaran
                    2020/2021.
                  </p>
                  <p class="font-weight-light" style="font-size: 11px">
                    {{ $t("homepage.posted_on_date") }} 10 nov 2019
                  </p>
                  <v-btn small class="grey--text">3 Hari Lagi</v-btn>
                </div>
              </div>
            </v-img>
          </v-card>
        </v-col> -->
        <!-- <v-col cols="12" sm="8" md="9"> -->
        <v-col cols="12">
          <v-slide-group v-model="model" show-arrows>
            <v-slide-item
              class="slide-wrapper mx-3"
              v-for="(card, i) in cards"
              :key="i"
            >
              <v-card class="text-left" height="375px" outlined>
                <v-img
                  v-if="
                    card.file_type == 'youtube' || card.file_type == 'image'
                  "
                  :src="card.thumbnail"
                  height="170"
                ></v-img>
                <div v-else class="pa-4">
                  <v-img :src="card.thumbnail" height="140" contain></v-img>
                </div>
                <v-card-text class="subtitle-1">
                  <div class="card-title-wrap">
                    <a
                      color="primary"
                      class="card-title-wrap text-truncate"
                      text
                      @click="
                        $router.push({
                          name: 'InformationDetail',
                          params: { type: 'announcement' },
                          query: {
                            id: card.id,
                            title: join(card.title)
                          }
                        })
                      "
                      >{{ card.title }}
                    </a>
                  </div>
                  <div class="caption card-caption-wrap">
                    <span v-html="truncateHtml(card.description)" />
                    <span v-if="card.description == isFalse">
                      {{ $t("homepage.no_description") }}
                    </span>
                  </div>
                  <span class="font-weight-light" style="font-size: 11px">
                    {{ $t("homepage.posted_on_date") }}
                    {{ dateFormat(card.created_date) }}
                  </span>

                  <span class="font-weight-light" style="font-size: 11px">
                    {{ $t(`app.${card.type}`) }} {{ $t(`app.for`) }}
                    {{ $t(`app.${card.type_for}`) }}
                  </span>

                  <v-row no-gutters style="font-size: 11px">
                    <a class="mr-2">
                      <v-icon
                        :color="card.status_likes ? 'primary' : ''"
                        small
                        @click="likeHandler(card.id)"
                        >mdi-thumb-up-outline</v-icon
                      >
                      <span
                        :style="darkmode ? 'color: white' : 'color: black'"
                        class="ml-1 grey--text"
                        >{{ card.likes }}</span
                      >
                    </a>
                    <a class="mr-2">
                      <v-icon small>mdi-eye-outline</v-icon>
                      <span
                        :style="darkmode ? 'color: white' : 'color: black'"
                        class="ml-1"
                        >{{ card.view }}</span
                      >
                    </a>
                    <v-spacer></v-spacer>
                    <SocialSharing small :data="setSharing(card)" />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div
            class="mt-4"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right mr-10'
            "
          >
            <v-btn
              text
              color="primary"
              small
              @click="$router.push('/information/announcement')"
              >{{ $t("homepage.view_more") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import SocialSharing from "@/components/SocialSharing";
import {
  getInformation,
  setLikes,
  getInformationEmployee
} from "@/api/admin/information";
import truncate from "truncate-html";
import moment from "moment/moment";
moment.locale("id");
import { mapGetters } from "vuex";

export default {
  props: {
    darkColor: {
      type: String,
      default: "black"
    }
  },
  components: {
    SocialSharing
  },
  created() {
    this.getListData();
  },
  computed: {
    ...mapGetters(["g_token"]),
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      page: 1,
      model: null,
      isFalse: "",
      queryData: {
        limit: 8,
        type: "announcement",
        sort: "created_at",
        order: "DESC"
      },
      cards: [],
      thumbnail: {
        pdf: require("@/assets/svg/thumbnail_pdf.svg"),
        video: require("@/assets/svg/thumbnail_video.svg"),
        nofileannouncement: require("@/assets/svg/thumbnail_nofile_announcement.svg")
      }
    };
  },
  methods: {
    setSharing(data) {
      const setUrl = `${
        window.location.origin
      }/information/announcement/detail?id=${data.id}&title=${this.join(
        data.title
      )}`;
      return {
        url: setUrl,
        title: data.title,
        description: data.description
      };
    },
    join(title) {
      return title.split(" ").join("-");
    },
    likeHandler(id) {
      if (this.g_token) {
        this.$Progress.start();
        setLikes({ id: id }).then(res => {
          if (res.data.code) {
            this.getListData();
          }
          this.$Progress.finish();
        });
      } else {
        this.$router.push("/login").then(() => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.login_first"),
            color: "warning"
          });
        });
      }
    },
    dateFormat: date => moment(date).format("DD MMM YYYY"),
    truncateHtml: html => truncate(html, 4, { byWords: true, stripTags: true }),
    splitUrl(url, onlyCode) {
      if (url) {
        const splitUrl = url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }
          if (onlyCode && idYoutube) {
            return idYoutube;
          } else if (onlyCode && !idYoutube) {
            return "";
          } else {
            return "https://www.youtube.com/embed/" + idYoutube;
          }
        }
      }
    },
    async getListData() {
      let response = [];
      try {
        if (this.g_token) {
          response = await getInformationEmployee(this.queryData);
        } else {
          response = await getInformation(this.queryData);
        }
        if (response.data.code) {
          const tempData = [];
          const data = response.data.data.data;

          data.map(r => {
            let thumbnail = "";
            if (r.file_type) {
              switch (r.file_type) {
                case "youtube": {
                  const code = this.splitUrl(r.thumbnail, "onlyCode");
                  if (code)
                    thumbnail = `https://img.youtube.com/vi/${code}/0.jpg`;
                  break;
                }
                case "pdf":
                  thumbnail = this.thumbnail.pdf;
                  break;
                case "video":
                  thumbnail = this.thumbnail.video;
                  break;
                default:
                  thumbnail = r.thumbnail;
                  break;
              }
            } else {
              thumbnail = this.thumbnail.nofileannouncement;
            }
            tempData.push({ ...r, thumbnail: thumbnail });
          });

          this.cards = tempData;
          this.total = response.data.data.total;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: response.data.message,
            color: "error"
          });
        }
      } catch (error) {
        this.$store.commit("CALL_SNACKBAR", {
          msg: error,
          color: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
  width: 250px;
  height: 400px;
}
.card-title-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-caption-wrap {
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
