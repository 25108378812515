var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{class:_vm.darkmode ? _vm.darkColor : 'white'},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.cards),function(card,i){return _c('v-slide-item',{key:i,staticClass:"slide-wrapper mx-3"},[_c('v-card',{staticClass:"text-left",attrs:{"height":"375px","outlined":""}},[(
                  card.file_type == 'youtube' || card.file_type == 'image'
                )?_c('v-img',{attrs:{"src":card.thumbnail,"height":"170"}}):_c('div',{staticClass:"pa-4"},[_c('v-img',{attrs:{"src":card.thumbnail,"height":"140","contain":""}})],1),_c('v-card-text',{staticClass:"subtitle-1"},[_c('div',{staticClass:"card-title-wrap"},[_c('a',{staticClass:"card-title-wrap text-truncate",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$router.push({
                        name: 'InformationDetail',
                        params: { type: 'announcement' },
                        query: {
                          id: card.id,
                          title: _vm.join(card.title)
                        }
                      })}}},[_vm._v(_vm._s(card.title)+" ")])]),_c('div',{staticClass:"caption card-caption-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.truncateHtml(card.description))}}),(card.description == _vm.isFalse)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("homepage.no_description"))+" ")]):_vm._e()]),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.$t("homepage.posted_on_date"))+" "+_vm._s(_vm.dateFormat(card.created_date))+" ")]),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.$t(`app.${card.type}`))+" "+_vm._s(_vm.$t(`app.for`))+" "+_vm._s(_vm.$t(`app.${card.type_for}`))+" ")]),_c('v-row',{staticStyle:{"font-size":"11px"},attrs:{"no-gutters":""}},[_c('a',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":card.status_likes ? 'primary' : '',"small":""},on:{"click":function($event){return _vm.likeHandler(card.id)}}},[_vm._v("mdi-thumb-up-outline")]),_c('span',{staticClass:"ml-1 grey--text",style:(_vm.darkmode ? 'color: white' : 'color: black')},[_vm._v(_vm._s(card.likes))])],1),_c('a',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"ml-1",style:(_vm.darkmode ? 'color: white' : 'color: black')},[_vm._v(_vm._s(card.view))])],1),_c('v-spacer'),_c('SocialSharing',{attrs:{"small":"","data":_vm.setSharing(card)}})],1)],1)],1)],1)}),1),_c('div',{staticClass:"mt-4",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right mr-10'},[_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.$router.push('/information/announcement')}}},[_vm._v(_vm._s(_vm.$t("homepage.view_more")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }